import { Component } from "react";
import { aboutHtml, loadingAboutHtml } from "./about.html";
import "./about.css";
import axios from "axios";

export class About extends Component {
  serviceUrl =
    "https://4benr9m65d.execute-api.us-east-1.amazonaws.com/default/yahyaakli-resume";

  constructor(props) {
    super(props);
    this.state = { content: "", loading: true };
  }

  retrieveContent() {
    return axios.get(this.serviceUrl).then((content) => {
      this.content = content.data;
      this.setState({ content: content.data, loading: false });
    });
  }

  render() {
    if (this.state.loading) {
      this.retrieveContent();
      return loadingAboutHtml();
    } else {
      let content = this.state.content;
      return aboutHtml(content);
    }
  }
}
