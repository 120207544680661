import { Component } from "react";
import { Preview } from "./services/preview.service";
import { subPreviewHtml } from "./sub-preview.html";

export class SubMenu extends Component {
  previewService = new Preview();

  render() {
    const content = this.previewService.retrieveContent();
    return subPreviewHtml(content);
  }
}
