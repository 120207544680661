import { SpecialItem } from "./components/special-item/special-item";

export const subPreviewHtml = (content) => {
  return (
    <div className="bg-gray-900">
      <div className="relative px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="mb-10 md:mb-16">
          <h1 className="text-gray-100 text-4xl lg:text-5xl font-bold text-center mb-4 md:mb-6">
            My values
          </h1>

          <p className="max-w-screen-md text-gray-500 md:text-lg text-center mx-auto">
            The values that guide my journey as a Software Engineer
          </p>
        </div>
        <div className="relative grid gap-5 sm:grid-cols-4 lg:grid-cols-3 ">
          {content.map((item, index) => (
            <SpecialItem
              title={item.title}
              content={item.content}
              key={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
