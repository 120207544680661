import { SubMenu } from "./components/sub-content-preview/sub-preview";
import "./content";

export const ContentHtml = () => {
  return (
    <div>
      <SubMenu />
    </div>
  );
};
