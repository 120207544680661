import { Component } from "react";
import "./home-header.css";

export class HomeHeader extends Component {
  render() {
    return (
      <section className="bg-gray-900">
        <div className="w-full px-4 py-20 mx-auto max-w-7xl md:w-4/5 lg:w-6/6 ">
          <div className="container flex justify-center mx-auto py-10">
            <img
              className="lg:block h-20 w-auto full "
              src="./img/yah-log-2b.png"
              alt="Workflow"
            />
          </div>
          <div className="container text-center mx-auto">
          <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
            Let your software take you to the moon
          </h1>
          <p className="mb-6 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">
            Welcome to Yahya Akli's Software Bunker. In this blog, you can find my background, resume, and software posts.
          </p>
          <a href="/about" className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900">
              Learn more
              <svg className="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
            </svg>
          </a>
          </div>
        </div>
      </section>
    );
  }
}
