import { Component } from "react";
import { Spinner } from "../../../spinner/spinner";
import { BlogPeviewSection } from "./components/blog-preview-section/blog-preview-section";
import "./blog-previews.css";
import axios from "axios";

export class BlogPeviews extends Component {
  serviceUrl =
    "https://5zu8h1w5o9.execute-api.us-east-1.amazonaws.com/default/blog";

  constructor(props) {
    super(props);
    this.state = {
      content: "",
      loading: true,
      selectedCategory: "", // State to store selected category
    };
  }

  retrieveBlogContent() {
    return axios.get(this.serviceUrl).then((content) => {
      this.setState({ content: content.data, loading: false });
    });
  }

  handleCategoryChange = (event) => {
    this.setState({ selectedCategory: event.target.value });
  };

  render() {
    if (this.state.loading) {
      this.retrieveBlogContent();
      return loadingblogPreviewsHtml();
    } else {
      let content = this.state.content;

      // Get unique categories
      const categories = [
        ...new Set(content.map((post) => post.categorie.toUpperCase())),
      ];

      // Filter blog posts based on the selected category
      const filteredBlogPosts =
        this.state.selectedCategory === "" || this.state.selectedCategory === "All"
          ? content
          : content.filter(
              (post) =>
                post.categorie.toUpperCase() === this.state.selectedCategory
            );

      return (
        <div className="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
          <div>
            <div className="mb-8 sm:max-w-xl">
              <label
                htmlFor="category"
                className="text-sm font-medium leading-5 text-gray-700"
              >
                Filter
              </label>
              <select
                id="category"
                name="category"
                value={this.state.selectedCategory}
                onChange={this.handleCategoryChange} // Handle the category change
                className="block w-full px-4 py-2 mt-1 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="All">All</option>
                {categories.map((category) => (
                  <option value={category} key={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mb-10 border-t border-b divide-y">
            {filteredBlogPosts.map((blogPost) => (
              <BlogPeviewSection
                blogPost={blogPost}
                key={blogPost.id}
                onClick={() => handleClick(blogPost.id)}
              />
            ))}
          </div>
        </div>
      );
    }
  }
}

function handleClick(id) {
  window.location.href = `/blog/${id}`;
}

export const loadingblogPreviewsHtml = () => {
  return <Spinner />;
};
