import { Spinner } from "../../../spinner/spinner";
import { BlogPeviewSection } from "./components/blog-preview-section/blog-preview-section";


export const blogPreviews = (blogPosts, categories) => {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div>
        <div className="mb-4 sm:max-w-xl">
          <label htmlFor="category" className="text-sm font-medium leading-5 text-gray-700">
            Filter
          </label>
          <select
            id="category"
            name="category"
            className="block w-full px-4 py-2 mt-1 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">All</option>
            {categories.map((category) => (
              <option value={category} key={category}>
                {category}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="mb-10 border-t border-b divide-y">
        {blogPosts.map((blogPost) => (
          <BlogPeviewSection blogPost={blogPost} key={blogPost.id} onClick={()=>handleClick(blogPost.id)}/>
        ))}
      </div>
    </div>
  );
};

function handleClick(id) {
  window.location.href = `/blog/${id}`;
};
export const loadingblogPreviewsHtml = () => {
  return <Spinner />;
};
