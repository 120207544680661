export const blogPreviewSectionHtml = (blogPost, onClick) => {
  return (
    <div className="grid py-8 sm:grid-cols-4">
      <div className="mb-4 sm:mb-0">
        <div className="space-y-1 text-xs font-semibold tracking-wide uppercase">
            {blogPost.categorie}
          <p className="text-gray-600">{blogPost.updatedAt}</p>
        </div>
      </div>
      <div className="sm:col-span-3 lg:col-span-2">
        <div className="mb-3" onClick={onClick}>
            <p className="text-3xl font-extrabold leading-none sm:text-4xl xl:text-4xl">
              {blogPost.title}
            </p>
        </div>
        <p className="text-gray-700">{blogPost.content.slice(0, 300) + '...'}</p>
        <br/>
        <button onClick={onClick} className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900">
              Read more
          </button>
      </div>
    </div>
  );
};
