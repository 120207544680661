import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export function UUIDGenerator(){
  const [uuidList, setUuidList] = useState([]);
  const [copiedUUID, setCopiedUUID] = useState(null);

  const generateUUID = () => {
    const newUUID = uuidv4();
    setUuidList([newUUID, ...uuidList]);
  };

  const handleCopy = (uuid) => {
    navigator.clipboard.writeText(uuid);
    setCopiedUUID(uuid);
    setTimeout(() => setCopiedUUID(null), 2000); // Reset after 2 seconds
  };

  const clearUUIDs = () => {
    setUuidList([]);
  };

  return (
    <div style={styles.container}>
      <h1>UUID Generator</h1>
      <button onClick={generateUUID} style={styles.button}>Generate UUID</button>
      <button onClick={clearUUIDs} style={styles.clearButton}>Clear UUIDs</button>
      <div style={styles.uuidList}>
        {uuidList.map((uuid, index) => (
          <div key={index} style={styles.uuidItem}>
            <span>{uuid}</span>
            <button onClick={() => handleCopy(uuid)} style={styles.copyButton}>Copy</button>
          </div>
        ))}
      </div>
      {copiedUUID && <p style={styles.copiedMessage}>Copied {copiedUUID} to clipboard!</p>}
    </div>
  );
};

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '600px',
    margin: '20px auto',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    textAlign: 'center',
  },
  button: {
    backgroundColor: '#2C3E50',
    color: '#fff',
    padding: '10px 15px',
    margin: '10px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  clearButton: {
    backgroundColor: '#f4eeec',
    color: '#272727',
    padding: '10px 15px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  uuidList: {
    marginTop: '20px',
    textAlign: 'left',
  },
  uuidItem: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#f8f9fa',
    padding: '10px',
    margin: '10px 0',
    border: '1px solid #ddd',
    borderRadius: '4px',
  },
  copyButton: {
    backgroundColor: '#2C3E50',
    color: '#fff',
    border: 'none',
    padding: '5px 10px',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  copiedMessage: {
    color: 'green',
    marginTop: '20px',
    fontSize: '16px',
  },
};