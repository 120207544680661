import { StepsBeatItem } from "./components/steps-beat-item/steps-beat-item";

export const stepsHtml = (item) => {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-10 mx-auto flex flex-wrap">
        <div className="flex flex-wrap w-full mb-20 flex-col items-center text-center">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
            {item.title}
          </h1>
          <p className="lg:w-1/2 w-full leading-relaxed text-gray-500">
            {item.description}
          </p>
        </div>

        {item.body.map((content, index) => (
          <StepsBeatItem id={index + 1} key={index} content={content} />
        ))}
      </div>
    </section>
  );
};
