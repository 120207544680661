import { CheckBox } from "./components/check-box/check-box";

export const checksHtml = (item) => {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-10 mx-auto">
        <div className="text-center mb-20">
          <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">
            {item.title}
          </h1>
          <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
            {item.description}
          </p>
        </div>
        <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
          {item.body.map((content, index) => (
            <CheckBox
              title={content.title}
              description={content.description}
              key={index}
            />
          ))}
        </div>
      </div>
    </section>
  );
};
