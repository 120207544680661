export class Preview {
  content = [
    {
      title: "Always learning",
      content: "Consistency is what transforms average into excellence.",
    },
    {
      title: "Respect",
      content: "Respct is the building stone of any productive environment.",
    },
    {
      title: "Integrity & Transparency",
      content:
        "It is better to conquer yourself than to win a thousand battles",
    },
    {
      title: "Passion & Creativity",
      content: "Imagination is more important knowledge.",
    },
    {
      title: "Curiosity & Expermentation",
      content: "Curiosity is the engine of achievement",
    },
    {
      title: "No Fear",
      content:
        "Engineer should be no limit solders, it is important to push one's boundries and learn new things.",
    },
  ];

  retrieveContent() {
    return this.content;
  }
}
