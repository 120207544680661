import { blogHtml, blogPost } from "./blog.html";
import "./blog.css";

import { useParams } from 'react-router-dom';

export function Blog(props) {
  const {id} = useParams();
  if(id){
    return blogPost(id);
  }
  return blogHtml();
}
