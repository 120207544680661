import React, { useState } from "react";
import './color-picker.css';


export function ColorPickerConverter() {
  const [colorHex, setColorHex] = useState("#272727"); // Initial color
  const [colorRgb, setColorRgb] = useState("rgb(255, 0, 0)");
  const [colorHsl, setColorHsl] = useState("hsl(0, 100%, 50%)");
  const recommendedColors = [
    { name: 'Ocean Blue', hex: '#1B4F72' },
    { name: 'Sunset Orange', hex: '#F39C12' },
    { name: 'Emerald Green', hex: '#2ECC71' },
    { name: 'Royal Purple', hex: '#8E44AD' },
    { name: 'Soft Pink', hex: '#FADBD8' },
    { name: 'Bright Yellow', hex: '#F4D03F' },
    { name: 'Sky Blue', hex: '#5DADE2' },
    { name: 'Coral Red', hex: '#E74C3C' },
    { name: 'Forest Green', hex: '#27AE60' },
    { name: 'Midnight Black', hex: '#2C3E50' },
  ];
  const [copiedColor, setCopiedColor] = useState(null);

  const handleCopy = (hex) => {
    navigator.clipboard.writeText(hex);
    setCopiedColor(hex);
    setTimeout(() => setCopiedColor(null), 2000); // Reset after 2 seconds
  };
  const hexToRgb = (hex) => {
    let r = 0,
      g = 0,
      b = 0;
    if (hex.length === 7) {
      r = parseInt(hex.slice(1, 3), 16);
      g = parseInt(hex.slice(3, 5), 16);
      b = parseInt(hex.slice(5, 7), 16);
    }
    return `rgb(${r}, ${g}, ${b})`;
  };

  const hexToHsl = (hex) => {
    let r = 0,
      g = 0,
      b = 0;
    if (hex.length === 7) {
      r = parseInt(hex.slice(1, 3), 16) / 255;
      g = parseInt(hex.slice(3, 5), 16) / 255;
      b = parseInt(hex.slice(5, 7), 16) / 255;
    }
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h,
      s,
      l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
        default:
          break;
      }
      h = h * 60;
    }
    return `hsl(${Math.round(h)}, ${Math.round(s * 100)}%, ${Math.round(
      l * 100
    )}%)`;
  };

  const handleColorChange = (e) => {
    const newColor = e.target.value;
    setColorHex(newColor);
    setColorRgb(hexToRgb(newColor));
    setColorHsl(hexToHsl(newColor));
  };

  return (
    <div className="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-8">
    <h1 className="color-picker-header">Color Picker</h1>
      <div style={styles.container}>
        <div style={styles.picker}>
          <input type="color" value={colorHex} onChange={handleColorChange} />
          <input
            type="text"
            value={colorHex}
            onChange={(e) => {
              const hex = e.target.value;
              if (/^#[0-9A-F]{6}$/i.test(hex)) {
                setColorHex(hex);
                setColorRgb(hexToRgb(hex));
                setColorHsl(hexToHsl(hex));
              }
            }}
            style={styles.input}
          />
        </div>

        <div style={styles.result}>
          <div>
            <label>HEX:</label>
            <input type="text" value={colorHex} readOnly style={styles.input} />
          </div>
          <div>
            <label>RGB:</label>
            <input type="text" value={colorRgb} readOnly style={styles.input} />
          </div>
          <div>
            <label>HSL:</label>
            <input type="text" value={colorHsl} readOnly style={styles.input} />
          </div>
        </div>

        <div
          style={{
            backgroundColor: colorHex,
            width: "25%",
            height: "100px",
            marginTop: "20px",
            border: "1px solid #ccc",
            borderRadius: "20px",
          }}
        ></div>
      </div>
      <div>
        <h1 className="recommended-color-header">Recommended Colors</h1>
        <div style={styles.container}>
            <div style={styles.grid}>
                {recommendedColors.map((color) => (
                <div
                    key={color.hex}
                    style={{ ...styles.colorBlock, backgroundColor: color.hex }}
                    onClick={() => handleCopy(color.hex)}
                >
                    <p style={styles.colorName}>{color.name}</p>
                    <p style={styles.hexCode}>{color.hex}</p>
                </div>
                ))}
            </div>
            {copiedColor && <p style={styles.copiedMessage}>Copied {copiedColor} to clipboard!</p>}
        </div>
      </div>
    </div>
  );
}

// Inline styles
const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    margin: "20px auto",
    padding: "20px",
    backgroundColor: "#f9f9f9",
    borderRadius: "20px",
  },
  picker: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
    borderRadius: "20px",
  },
  input: {
    width: "100%",
    padding: "5px",
    marginTop: "5px",
    fontSize: "16px",
    backgroundColor: "#f9f9f9",
  },
  result: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "10px",
    marginBottom: "20px",
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
    gap: '20px',
  },
  colorBlock: {
    padding: '20px',
    color: '#fff',
    textAlign: 'center',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'transform 0.2s ease-in-out',
  },
  colorName: {
    fontSize: '16px',
    marginBottom: '5px',
  },
  hexCode: {
    fontSize: '14px',
  },
  copiedMessage: {
    color: 'green',
    marginTop: '20px',
    textAlign: 'center',
    fontSize: '18px',
  },
};
