import { Component } from "react";
import { specialItemHtml } from "./special-item.html";

export class SpecialItem extends Component {
  render() {
    var title = this.props?.title;
    var content = this.props?.content;
    return specialItemHtml(title, content);
  }
}
