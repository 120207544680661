import React, { useState } from 'react';
import yaml from 'js-yaml';

export function YAMLFormatter(props) {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [error, setError] = useState('');

  const handleFormat = () => {
    try {
      const parsedYaml = yaml.load(input); // Parse YAML to JavaScript object
      const formattedYaml = yaml.dump(parsedYaml, { indent: 2 }); // Dump it back to YAML with formatting
      setOutput(formattedYaml);
      setError('');
    } catch (err) {
      setError(`Invalid YAML ${err.message}`);
      setOutput('');
    }
  };

  const handleClear = () => {
    setInput('');
    setOutput('');
    setError('');
  };

  return (
    <div style={styles.container}>
      <h1>YAML Formatter</h1>
      <textarea
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Enter your YAML here..."
        style={styles.textArea}
      />
      {error && <p style={styles.error}>{error}</p>}
      <button onClick={handleFormat} style={styles.button}>Format YAML</button>
      <button onClick={handleClear} style={styles.clearButton}>Clear</button>
      <h3>Formatted YAML:</h3>
      <textarea
        value={output}
        readOnly
        placeholder="Formatted YAML will appear here..."
        style={styles.textArea}
      />
    </div>
  );
};

// Inline styles for simplicity
const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  textArea: {
    width: '100%',
    height: '200px',
    padding: '10px',
    marginBottom: '10px',
    fontSize: '16px',
    border: '2px solid #ccc',
    borderRadius: '4px',
  },
  button: {
    backgroundColor: '#28a745',
    color: '#fff',
    padding: '10px 15px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginRight: '10px',
  },
  clearButton: {
    backgroundColor: '#f4eeec',
    color: '#272727',
    padding: '10px 15px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  error: {
    color: '#880808',
  },
};
