import React, { useState } from 'react';

export function JSONFormatter(props) {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [error, setError] = useState('');

  const handleFormat = () => {
    try {
      // Preprocess input to replace single quotes with double quotes
      let sanitizedInput = input
        .replace(/'/g, '"') // Replace all single quotes with double quotes
        .replace(/(\w+)\s*:/g, '"$1":') // Add quotes around object keys if missing
        .replace(/,\s*}/g, '}'); // Correct trailing commas
      
      const formattedJson = JSON.stringify(JSON.parse(sanitizedInput), null, 2);
      setOutput(formattedJson);
      setError('');
    } catch (err) {
      setError(`Invalid JSON: ${err.message}`);
      setOutput('');
    }
  };

  const handleClear = () => {
    setInput('');
    setOutput('');
    setError('');
  };

  return (
    <div className="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
      <div class="p-4 my-4 text-sm text-gray-800 rounded-lg bg-gray-50 dark:text-gray-800" role="alert">
        <b>Unlike other formatters, this will correct some of your mistakes like missing quotes around keys and trailing commas.</b>
      </div>
      <h3>Original JSON:</h3>
      <textarea
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Enter your JSON here..."
        style={styles.textArea}
      />
      {error && <p style={styles.error}>{error}</p>}
      <button onClick={handleFormat} style={styles.button}>Format JSON</button>
      <button onClick={handleClear} style={styles.clearButton}>Clear</button>
      <h3>Formatted JSON:</h3>
      <textarea
        value={output}
        readOnly
        placeholder="Formatted JSON will appear here..."
        style={styles.textArea}
      />
    </div>
  );
};

const styles = {
  container: {
    // maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  textArea: {
    width: '100%',
    height: '300px',
    padding: '15px',
    marginBottom: '10px',
    fontSize: '16px',
    border: '2px solid #ccc',
    borderRadius: '20px',
  },
  button: {
    backgroundColor: '#28a745',
    color: '#fff',
    padding: '10px 15px',
    border: 'none',
    borderRadius: '10px',
    cursor: 'pointer',
    marginRight: '10px',
  },
  clearButton: {
    backgroundColor: '#f4eeec',
    color: '#272727',
    padding: '10px 15px',
    border: 'none',
    borderRadius: '10px',
    cursor: 'pointer',
  },
  error: {
    color: '#880808',
  },
};
