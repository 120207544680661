import React, { useState } from "react";
import Ajv from "ajv";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { nightOwl } from "react-syntax-highlighter/dist/esm/styles/prism";
import { FiCopy } from "react-icons/fi";

export function JsonSchemaValidator() {
  const [jsonData, setJsonData] = useState("");
  const [jsonSchema, setJsonSchema] = useState("");
  const [validationResult, setValidationResult] = useState(null);
  const exampleSchema = `{
  "type": "object",
  "properties": {
    "productName": { "type": "string" },
    "price": { "type": "number" },
    "inStock": { "type": "boolean" },
    "specifications": {
      "type": "object",
      "properties": {
        "processor": { "type": "string" },
        "ram": { "type": "string" },
        "storage": { "type": "string" }
      },
      "required": ["processor", "ram", "storage"]
    }
  },
  "required": ["productName", "price", "inStock", "specifications"]
}`;
  const exampleData = `{
    "productName": "Laptop",
    "price": 999.99,
    "inStock": true,
    "specifications": {
        "processor": "Intel i7",
        "ram": "16GB",
        "storage": "512GB SSD"
    }
}`;
  const ajv = new Ajv();

  const handleValidate = () => {
    try {
      const parsedData = JSON.parse(jsonData);
      const parsedSchema = JSON.parse(jsonSchema);

      const validate = ajv.compile(parsedSchema);
      const isValid = validate(parsedData);

      if (isValid) {
        setValidationResult({ isValid: true, errors: null });
      } else {
        setValidationResult({ isValid: false, errors: validate.errors });
      }
    } catch (error) {
      setValidationResult({
        isValid: false,
        errors: [{ message: "Invalid JSON or Schema syntax" }],
      });
    }
  };

  const handleClear = () => {
    setJsonData("");
    setJsonSchema("");
    setValidationResult(null);
  };

  return (
    <div style={styles.container}>
      <h1>JSON Schema Validator</h1>
      <div style={styles.inputContainer}>
        <textarea
          placeholder="Enter your JSON data..."
          value={jsonData}
          onChange={(e) => setJsonData(e.target.value)}
          style={styles.textArea}
        />
        <textarea
          placeholder="Enter your JSON schema..."
          value={jsonSchema}
          onChange={(e) => setJsonSchema(e.target.value)}
          style={styles.textArea}
        />
      </div>
      <div style={styles.buttonContainer}>
        <button onClick={handleValidate} style={styles.button}>
          Validate
        </button>
        <button onClick={handleClear} style={styles.clearButton}>
          Clear
        </button>
      </div>
      {validationResult && (
        <div style={styles.resultContainer}>
          {validationResult.isValid ? (
            <p style={styles.validMessage}>
              JSON is valid according to the schema!
            </p>
          ) : (
            <div>
              <p style={styles.invalidMessage}>
                JSON is invalid according to the schema:
              </p>
              <ul style={styles.errorList}>
                {validationResult.errors.map((error, index) => (
                  <li key={index}>{error.message}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
      <h1>Example:</h1>
      <div>
        <h2>JSON Data</h2>
        <div className="code-block">
          <button
            className="copy-button"
            onClick={() => {
              navigator.clipboard.writeText(exampleSchema);
            }}
          >
            <FiCopy />
          </button>
            <SyntaxHighlighter
            language="json"
            style={nightOwl}
            customStyle={{ borderRadius: "20px" }}
            >
            {exampleData}
            </SyntaxHighlighter>
        </div>
        <h2>JSON Schema</h2>
        <div className="code-block">
          <button
            className="copy-button"
            onClick={() => {
              navigator.clipboard.writeText(exampleSchema);
            }}
          >
            <FiCopy />
          </button>
          <SyntaxHighlighter
            language="json"
            style={nightOwl}
            customStyle={{ borderRadius: "20px" }}
          >
            {exampleSchema}
          </SyntaxHighlighter>
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    maxWidth: "900px",
    margin: "10px auto",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "8px",
  },
  inputContainer: {
    display: "flex",
    gap: "20px",
  },
  textArea: {
    width: "100%",
    height: "300px",
    padding: "10px",
    fontSize: "14px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  buttonContainer: {
    marginTop: "20px",
    textAlign: "center",
  },
  button: {
    backgroundColor: "#28a745",
    color: "#fff",
    padding: "10px 20px",
    marginRight: "10px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  clearButton: {
    backgroundColor: '#f4eeec',
    color: '#272727',
    padding: '10px 15px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  resultContainer: {
    marginTop: "20px",
  },
  validMessage: {
    color: "green",
    fontWeight: "bold",
  },
  invalidMessage: {
    color: "red",
    fontWeight: "bold",
  },
  errorList: {
    color: "red",
  },
};
