import { Component } from "react";
import "./blog-post.css";
import { Spinner } from "../../../spinner/spinner";
import axios from "axios";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nightOwl } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { FiCopy } from 'react-icons/fi';


const loadingblogPreviewsHtml = () => {
  return <Spinner />;
};


export class BlogPost extends Component {
  serviceUrl =
    "https://5zu8h1w5o9.execute-api.us-east-1.amazonaws.com/default/blog";

  constructor(props) {
    super(props);
    this.state = { content: "", loading: true };
  }

  retrieveBlogContent(id) {
    return axios.get(`${this.serviceUrl}/${id}`).then((content) => {
      this.content = content.data;
      this.setState({ content: content.data, loading: false });
    });
  }
  render() {
    const id = this.props.id;
    if (this.state.loading) {
      this.retrieveBlogContent(id);
      return loadingblogPreviewsHtml();
    } else {
      let content = this.state.content;
      return (
        <div className="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-8">
          <button onClick={() => window.location.href = '/blog'} className="back-button">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width="24" height="24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <div className="post-title-section">
            <h1 className="title">{content.title}</h1>
            <h2 className="subtitle"><span className="category">{content.categorie.toUpperCase()}</span> {content.updatedAt}</h2>
          </div>
          <div className="post-content-section">
            <MarkdownRenderer content={content.content} />
          </div>
        </div>
      );
    }
  }
}

const MarkdownRenderer = ({ content }) => {
  return (
    <div className="markdown-content">
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '');
            return !inline && match ? (
              <div className="code-block">
                <button
                  className="copy-button"
                  onClick={() => {
                    navigator.clipboard.writeText(children);
                  }}
                >
                  <FiCopy />
                </button>
                <SyntaxHighlighter
                  style={nightOwl}
                  language={match[1]}
                  customStyle={{ borderRadius: '20px' }}
                  PreTag="div"
                  {...props}
                >
                  {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
              </div>
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            );
          },
        }}
      >
        {content}
      </ReactMarkdown>
    </div>
  );
};
