import "./App.css";
import React, { Suspense } from "react";
import { Component } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./components/home/home";
import { About } from "./components/about/about";
import { Blog } from "./components/blog/blog";
import { Docs } from "./components/docs/docs";
import { QueryDisplayContent } from "./components/querycontent/querycontent";
import { Tools } from "./components/tools/tools";

class App extends Component {
  render() {
    return (
      <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/docs" element={<Docs />} />
        <Route path="/keys" element={<QueryDisplayContent />} />
        <Route path="/blog/:id" element={<Blog id={this.props}/>} />
        <Route path="/tools" element={<Tools />}/>
        <Route path="/tools/:name" element={<Tools />}/>
        </Routes>
      </Suspense>
      </Router>
    );
  }
}

export default App;
