import { DarkHeader } from "../dark-header/dark-header";
import { MainMenu } from "../menu/menu";
import { ToolsDisplay } from "./tools-display";
import { useParams } from 'react-router-dom';
import { JSONFormatter } from "./formatters/json-formatter";
import { YAMLFormatter } from "./formatters/yaml-formatter";
import { ColorPickerConverter } from "./design/color-picker";
import { UUIDGenerator } from "./generators/uuid";
import { JsonSchemaValidator } from "./validators/json-schema-validator";

export function Tools(props) {
  const {name} = useParams();
  switch(name){
    case "json-formatter":
      return (
        <div>
          <MainMenu location="tools" />
          <DarkHeader title="JSON Formatter" />
          <JSONFormatter />
        </div>
      ); 
    case "yaml-formatter":
      return (
        <div>
          <MainMenu location="tools" />
          <DarkHeader title="YAML Formatter" />
          <YAMLFormatter />
        </div>
      );
    case "color-picker":
      return (
        <div>
          <MainMenu location="tools" />
          <DarkHeader title="Color Picker" />
          <ColorPickerConverter />
        </div>
      );
    case "uuid-generator":
      return (
        <div>
          <MainMenu location="tools" />
          <DarkHeader title="UUID Generator" />
          <UUIDGenerator />
        </div>
      );
    case "json-schema-validator":
      return (
        <div>
          <MainMenu location="tools" />
          <DarkHeader title="JSON Schema Validator" />
          <JsonSchemaValidator />
        </div>
      );
    default:
      return (
        <div>
          <MainMenu location="tools" />
          <DarkHeader title="Useful Software Tools" />
          <ToolsDisplay />
        </div>
      );    
  }
  
}
