export const specialItemHtml = (title, content) => {
  return (
    <div className="flex bg-gray-900 border rounded-lg divide-x">
      <div className="flex items-center text-indigo-500 p-2 md:p-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 md:w-8 h-6 md:h-8"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>

      <div className="p-4 md:p-6">
        <h3 className="text-gray-100 text-lg md:text-xl font-semibold mb-2">{title}</h3>
        <p className="text-gray-100">{content}</p>
      </div>
    </div>
  );
};
