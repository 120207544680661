import { DarkHeader } from "../dark-header/dark-header";
import { BlogPost } from "./components/blog-post/blog-post";
import { MainMenu } from "../menu/menu";
import { BlogPeviews } from "./components/blog-previews/blog-previews";

export const blogHtml = () => {
  var titleArray = ["My doodles blog", "Software Bunker blog"];
  var randomIndex = Math.floor(Math.random() * titleArray.length);
  var title = titleArray[randomIndex];
  return (
    <div>
      <MainMenu location="blog" />
      <DarkHeader title={title} />
      <BlogPeviews />
    </div>
  );
};


export const blogPost = (id) => {
  return (
    <div>
      <MainMenu location="blog" />
      <BlogPost id={id}/>
    </div>
  );
};