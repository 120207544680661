import { Component } from "react";
import { menuHtml } from "./menu.html";
import "./menu.css";

export class MainMenu extends Component {
  render() {
    const activePage = (location) => location === this.props.location;
    const navigation = [
      { name: "Dashboard", href: "/", current: activePage("home") },
      { name: "About me", href: "/about", current: activePage("about") },
      { name: "Blog", href: "/blog", current: activePage("blog") },
      { name: "Software Tools", href: "/tools", current: activePage("tools") },
    ];

    return menuHtml(navigation, this.props?.navColor, this.props?.logoDisabled);
  }
}
