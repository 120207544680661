import { ContentPreview } from "./components/content-preview/content";
import { MainMenu } from "../menu/menu";
import { Sayings } from "./components/sayings/sayings";
import { HomeHeader } from "./components/home-header/home-header";

export const homeHtml = () => {
  return (
    <div>
      <MainMenu location="home" navColor="900" logoDisabled="true"/>
      <HomeHeader></HomeHeader>
      <ContentPreview />
      <Sayings />
    </div>
  );
};
