export const sayingsHtml = () => {
  return (
    <section className="bg-gray-900">
      <div className="w-full px-4 py-20 mx-auto text-center max-w-7xl md:w-4/5 lg:w-4/6">
        <h1 className="mt-3 mb-10 text-xl font-extrabold text-white md:leading-snug md:text-3xl">
          My Top Two Favorite Quotes
          <br />
          <br />
          <span className="text-white bg-transparent bg-clip-border xl:bg-clip-text xl:text-transparent xl:bg-gradient-to-r from-green-400 to-purple-500">
            {" "}
            Consistency{" "}
          </span>{" "}
          is what transforms average into excellence.
          <br />
          <span className="text-white bg-transparent bg-clip-border xl:bg-clip-text xl:text-transparent xl:bg-gradient-to-r from-green-400 to-purple-500">
            {" "}
            Imagination{" "}
          </span>{" "}
          is more important than knowledge.
        </h1>
      </div>
    </section>
  );
};
